@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;500;700&family=Quicksand:wght@300;500;700&display=swap');

:root{
    --initial: -900px;
    /* --blue: #0078d7; */
    --blue: #7a09eb;
}

html {
    box-sizing: border-box;
    font-size: 16px;
    overflow: hidden;
}
  
*, *:before, *:after {
box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

a{
    text-decoration: none;
    color: black;
    padding: 0 0.2rem;
}

a:hover{
    color: white;
}

.app{
    background: #ededed;
    height: 100vh;
    display: flex;
    align-items: center;
}

.buttons{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.lang-button{
    background: gold;
    margin-top: 1rem;
    margin-right: 3rem;
    padding: 1rem;
    font-family: "Quicksand";
    font-size: 1.2rem;
}

.lang-button:hover{
    cursor: pointer;
}

.container{
    position: relative;
    /* background: tomato; */
    width: 100vw;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-top: 7rem; */
}

.blur{
    position: absolute;
    height: 15rem;
    width: 100%;
    top: -15rem;
    left: 0;
    background: linear-gradient(#ededed, #ededed,transparent);
    z-index: 1;
    margin-bottom: 7rem;;
}

.nav, .view{
    height: 100%;
}

.nav{
    /* background: turquoise; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    font-family: "Cormorant";
    font-size: 9rem;
    padding: 2rem;
    font-weight: bold;
    color: #ededed;
    text-shadow: -2px 2px 4px 
            rgba(0, 0, 0, 0.5), 
            2px -2px 0 
            rgba(255, 255, 255, 0.9);
}

.nav-title{
    text-decoration: underline 0px solid transparent;
    transition: all 0.3s ease-out;
    width: max-content;
}

.nav-title:hover{
    cursor: pointer;
    text-decoration: underline 15px solid #ededed;
    text-shadow: 2px 2px 4px 
            rgba(0, 0, 0, 0.5), 
            2px 2px 0 
            rgba(255, 255, 255, 0.9);
    text-underline-offset: 2rem;
}

.view{
    /* background: plum; */
    position: relative;
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    margin-right: 2rem;
    /* overflow-x: hidden; */
}

.view-item-box{
    position: relative;
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Cormorant";
    font-size: 1.3rem;
    /* background: white; */
    perspective: 2000px;
}

.view-item{
    background: var(--blue);
    color: white;
    /* position: absolute; */
    position: relative;
    width: 100%;
    height: 100%;
    top: var(--initial);
    transition: all 0.5s cubic-bezier(0,1,.69,1.81);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.5rem;
    transform-style: preserve-3d;
}

.item-front, .item-back{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.view-item-box:hover .view-item{
    transform: rotateY(180deg);
}

.view-item-box:hover .item-back{
    backface-visibility: visible;
}
.view-item-box:hover .project-title{
    z-index: 0;
}

.item-back{
    transform: rotateY(180deg);
    background: gold;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item-back-title{
    background: var(--blue);
    width: 100%;
    text-align: center;
    padding: 1rem;
}

.project-description{
    padding: 1rem 1rem;
    margin: 0 2rem;
    font-family: "Cormorant";
    color: black;
    border-bottom: 3px dotted black;

}

.project-description a{
    color: white;
    font-weight: bold;
}

.git-icon svg{
    fill: gold;
    height: 4rem;
    transition: all 0.2s ease-out;
}

.git-icon:hover svg{
    fill: white;
}

.img-box{
    background: red;
    position: absolute;
    /* height: 100%; */
    display: flex;
    justify-content: center;
}

.image{
    filter: blur(5px);
    z-index: 0;
}

.project-title{
    position: relative;
    z-index: 1;
    background: gold;
    /* width: max-content; */
    padding: 1rem 0;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    color: black;
    font-family: "Quicksand";
}

.project-summary{
    /* background: gainsboro; */
    font-size: 2.6rem;
    padding: 1rem 1rem;
    margin: 0 2rem;
    font-weight: bold;
    border-bottom: 3px dotted white;
}

.footer{
    position: absolute;
    bottom: 0;
    left: 0;
    background: gold;
    color: black;
    width: 100%;
    height: 5rem;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tools-title{
    font-weight: bold;
}

.project-tools{
    text-align: center;
    margin: 0.5rem 0;
}

.project-link{
    margin: 0.5rem;
    text-align: end;
}

.video{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.about{
    flex-direction: row;
    /* background: transparent; */
    padding: 0;
    align-items: center;
    font-family: "Cormorant";
    font-weight: bold;
    height: min-content;
}

.about:hover .about-img{
    filter: none;
}

.about-img{
    height: 100%;
    object-fit: cover;
    transition: all 4.5s ease;
    filter: invert();
}

.about-text{
    font-size: 1.4rem;
    padding: 1.5rem;
    width: 100%;
    position: relative;
    /* border-bottom: 3px dotted white; */
}

.skill{
    /* background: rgb(68, 67, 64); */
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}
.skill-rating{
    width: max-content;
    
}
.star{
    height: 1.2rem;
    transition: all 0.1s ease-out;
    fill: white;

}

.paperclip{
    fill: white;
    height: 1.2rem;
}

.skill:hover .star, .skill:hover .paperclip{
    fill: gold;
}
.skill:hover .skill-text{
    text-decoration: underline;
    cursor: default;
}

.mario{
    position: absolute;
    height: 80px;
    top: 125%;
    right: 5%;
    transition: all 0.1s ease-in-out;
}

div:nth-child(10):hover ~ img{
    top: 92%;
}

.project-link{
    display: flex;
    justify-content: center;
    
}

.project-link-icon{
    position: absolute;
    bottom: 1rem;
    height: 3rem;
    fill: var(--blue);
}

.project-link-icon:hover{
    fill: white;
}

.contact-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}

.contact-container-inner{
    display: flex;
    gap: 1rem;
    /* background: dodgerblue; */
    height: 100%;
}

.contact-form{
    width: 50%;
    height: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Quicksand";
    gap: 0;
}

.submitButton{
   border-radius: 0;
   border: none;
   /* padding: 1rem; */
   font-family: "Quicksand";
   width: 100%;
   font-size: 2.5rem;
   letter-spacing: 5px;
   font-weight: bold;
   background: #ededed;
   transition: all 0.3s ease-out;
}

.submitButton:hover{
    background: gold;
    color: var(--blue);
    cursor: pointer;
}

input, textarea{
    border: none;
    width: 100%;
    padding: 1rem;
    font-family: "Quicksand";
    font-size: 1.5rem;
    transition: all 0.3s ease-out;
}

input:focus, textarea:focus{
    outline: none;
    background: gold;
    border-radius: 10px;
}

textarea{
    resize: none;
    height: 16rem;
}

.carbonCopy{
    background: white;
    display: flex;
    justify-content: flex-start;
}

.ccLabel{
    background: gold;
    width: 100%;
    padding: 1rem;
    text-align: center;
}

.video-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    position: relative;
}

.contact-video{
    background: var(--blue);
    width: 100%;
    object-fit: cover;
    position: absolute;
}

.hidden{
    display: none;
}

.success-message-box{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    color: gold;
    font-size: 6rem;
    font-family: "Quicksand";
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.success-message{
    text-align: center;
    animation: flash 1s 6;
}

@keyframes flash {
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.test-button{
    padding: 1rem;
    background: red;
}


.test-button:hover{
    cursor: pointer;
    background: gold;
}

@media (max-width: 1920px){
    .nav{
        font-size: 6rem;
    }
}

@media (max-width: 1700px){
    .container{
        /* background: tomato; */
        flex-direction: column-reverse;
        height: 80vh;
    }
    .about{
        justify-content: center;
    }
    .nav{
        /* background: turquoise; */
        flex-direction: row;
        height: max-content;
        font-size: 5rem;
        gap: 5rem;
        padding: 0;
    }
    .nav-title{
        letter-spacing: 0.5rem;
    }
    .view{
        margin: 0;
        height: 700px;
    }
    .view-item{
        height: 100%;
    }
    .about-img{
        margin-right: 8rem;
    }
    .about-text{
        margin-left: 8rem;
    }
}

@media (max-width: 1420px){
    .about-img{
        margin-right: 6rem;
    }
    .about-text{
        margin-left: 6rem;
    }
}

@media (max-width: 1375px){
    .about-img{
        height: 70%;
        margin-right: 1rem;
    }
}

@media (max-width: 1280px){
    .about-img{
        height: 60%;
        margin-right: 0;
    }
    .about-text{
        margin-left: 0;
    }

    .nav-title{
        font-size: 4rem;
    }

    .project-description{
        margin: 0 1rem;
    }
    .project-summary{
        font-size: 2.5rem;
    }
}

@media(max-width:1130px){
    .project-title{
        font-size: 1.5rem;
    }
    .project-description{
        padding: 0.5rem 0;
    }
    .project-summary{
        padding: 1rem 0;
        font-size: 2.5rem;
    }
}

@media(max-width: 1080px){
    .about{
        padding: 3rem;
    }
    .about-img{
        display: none;
    }
    /* TODO: MAKE NAV APPEAR FROM MENU */
}